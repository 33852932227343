<template>
  <v-container>
    <div class="d-flex align-center mb-4">
      <h1 class="text-h5">
        <span class="work-sans">Lista de usuários</span>
      </h1>
    </div>

    <FilterGroup class="mb-4" :filters="methods" :queryKey="'type'" />

    <loader-content v-if="loading" />

    <section v-else>
      <FilterInput
        class="mb-4"
        :dataFilters="require('@/assets/filters/users.json')"
      />

      <v-card class="shadow rounded-lg mb-6" color="backgorund">
        <v-data-table
          class="transparent"
          :headers="_headers"
          :items="customers"
          hide-default-footer
          disable-pagination
          disable-sort
        >
          <!-- user -->
          <template v-slot:[`item.name`]="{ item }">
            <router-link :to="`/user/${item.userID || item.id}`">
              <v-chip color="primary" link>
                <v-icon left small>mdi-account</v-icon>

                <span>{{ item.name || item.nome }}</span>
              </v-chip>
            </router-link>
          </template>

          <!-- balance -->
          <template v-slot:[`item.balance`]="{ item }">
            R$ {{ currencyMask(item.saldo) }}
          </template>
        </v-data-table>
      </v-card>

      <!-- pagination -->
      <v-pagination
        v-model="pagination.page"
        color="primary"
        :length="pagination.pageCount"
        :total-visible="10"
        @input="getData()"
      />
    </section>
  </v-container>
</template>

<script>
import { request } from "@/services";
import { currencyMask, handlePagination } from "@/utils";
import FilterGroup from "@/components/filters/FilterGroup";
import FilterInput from "@/components/filters/FilterInput";

export default {
  name: "Customers",

  data() {
    return {
      loading: true,
      methods: [
        { label: "Todos", value: "" },
        { label: "Aguardando aprovação", value: "pending-approve" },
        { label: "Sem contrato", value: "without-contract" },
      ],
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
      },
      customers: [],
    };
  },

  components: {
    FilterGroup,
    FilterInput,
  },

  beforeMount() {
    this.getData();
  },

  watch: {
    ["$route.query"](value) {
      this.handleQuery(value);
    },
  },

  computed: {
    _headers() {
      // kyc headers
      if (this.$route.query.type === "pending-approve")
        return [
          { text: "ID", value: "id" },
          { text: "Nome", value: "name" },
          { text: "Descrição", value: "documentoDesc" },
          { text: "Data de solicitação", value: "data" },
        ];

      // detault
      return [
        { text: "ID", value: "id" },
        { text: "Nome", value: "name" },
        { text: "E-mail", value: "email" },
        { text: "Saldo", value: "balance" },
        { text: "Registrado em", value: "registerDate" },
      ];
    },
  },

  methods: {
    async getData(filter = {}) {
      try {
        const payload = {
          method: this.handleMethod(),
          page: this.pagination.page - 1,
          ...filter,
        };

        await request(payload).then(async (res) => {
          this.customers = res.users || res.solicitacoes;
          this.pagination.pageCount = handlePagination(res.total);
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    handleQuery(value) {
      this.loading = true;
      this.pagination.page = 1;

      const query = {
        FiltroUserID: value.id || undefined,
        nameFilter: value.name || undefined,
        emailFilter: value.email || undefined,
      };

      this.getData(query);
    },

    handleMethod() {
      const query = this.$route.query.type;

      if (query === "pending-approve") return "getSolicitacoesDocumentos";
      if (query === "without-contract") return "listUserWithKycAndNoContract";

      return "listUsers";
    },

    currencyMask,
  },
};
</script>

<style></style>
